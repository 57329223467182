/*================================================================================
	Item Name: Vuexy - Vuejs, HTML & Laravel Admin Dashboard Template
	Author: PIXINVENT
	Author URL: http://www.themeforest.net/user/pixinvent
================================================================================

NOTE:
------
PLACE HERE YOUR OWN SCSS CODES AND IF NEEDED, OVERRIDE THE STYLES FROM THE OTHER STYLESHEETS.
WE WILL RELEASE FUTURE UPDATES SO IN ORDER TO NOT OVERWRITE YOUR STYLES IT'S BETTER LIKE THIS.  */

/* Navbar Custom Width */
@import '@core/scss/angular/libs/toastr.component.scss';

// .main-menu{
// 	border-right: solid #43AB49 1px;
// }

.dark-layout .main-menu{
	border-right: none;
}

.main-menu .navbar-header {
	height: 5rem;
 
	.navbar-brand {
		margin-top: 0.35rem;

		.brand-logo img{
			height: 5rem;
			width: 100%;
			max-width: unset;
			max-height: 46px;
		}
	}
}

.header-navbar.floating-nav {
    margin-top: 1.3rem;
    border-radius: 0.428rem;
}

.vertical-layout.vertical-menu-modern.menu-collapsed .header-navbar.floating-nav {
	width: calc(100vw - (100vw - 100%) - 74px) !important;
}
/* End of Navbar Custom Styling */

/* Audio Player CSS*/
audio::-webkit-media-controls-panel {
  background-color: $primary;
}

audio::-webkit-media-controls-volume-slider {
  background-color: #B1D4E0;
  border-radius: 25px;
  padding-left: 8px;
  padding-right: 8px;
}

/* End of Audio Player CSS*/

.breadcrumb{
	font-size: 1.5rem;
	padding-left: 0;
}

.breadcrumb:not([class*=breadcrumb-]) .breadcrumb-item + .breadcrumb-item:before{
	margin-top: 0.35rem;
}

.font-white{
	color: #ffffff !important;
}

.match-height > .w-lg-5{
	display: flex;
}

@include media-breakpoint-up(lg) {
	.w-lg-5{
		flex: 0 0 20%;
		max-width: 20%;
		position: relative;
		width: 100%;
		padding-right: 1rem;
		padding-left: 1rem;
	}

}

label{
	font-size: 1.1rem;
}

.main-menu.menu-light .navigation > li > a,
.main-menu.menu-dark .navigation > li > a {
	margin: 15px 15px;
}

.main-menu.menu-light .navigation > li > a{
	img:not(.black){
		display: none;
	}
}

.main-menu.menu-dark .navigation > li > a{
	img:not(.gray){
		display: none;
	}
}

.main-menu.menu-light .navigation > li.active > a,
.main-menu.menu-dark .navigation > li.active > a{
	img:not(.white){
		display: none;
	}
	img.white{
		display: inline-block;
	}
}

.top-caption{
	top: 10px;
}
	
.p-carousel-header{
  margin-bottom: 1em;
}

.top-margin-offset{
	margin-top: -1.5rem;
}

@media (max-width:576px){
	.top-margin-offset{
		margin-top: unset;
	}

	.w-md-80{
		width: 80%;
	}
}

.w-md-80{
	width: 100%;
}

.toast-container .ngx-toastr{
	width: 350px;
}

.toast .toast-title,.toast-container .ngx-toastr{
	font-size: 1.25rem !important;
}

.border-2{
	border-width: 2px !important;
}

.attached-file{
	border-color: $secondary;
}

.underline{
	text-decoration: underline;
}

.justify-content-evenly{
	justify-content: space-evenly !important;
}

.text-black{
	color: #191919 !important;
}

@media (min-width:576px){
	.w-md-80{
		width: 80%;
	}
}