@import '@core/scss/core.scss';
@import './assets/scss/styles';
@import "./../node_modules/@swimlane/ngx-datatable/assets/icons.css";
@import "~@ng-select/ng-select/themes/default.theme.css";
// @import './@core/scss/angular/libs/datatables.component.scss';


html,
body {
    height: 100%;
}

body {
    margin: 0;
    font-family: 'Poppins', sans-serif;
}